"use strict";

function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
if (!window.$) {
  window.$ = jQuery;
}
function App() {
  $(document).on("click", 'a[href^="#"]', function (event) {
    var href = $(this).attr("href");
    if (href === '#') return;
    var $element = $(href);
    if ($element) {
      event.preventDefault();
      $("html, body").animate({
        scrollTop: $element.offset().top
      }, 1000, "linear");
    }
  });

  // scroll header
  var setFixedHeader = function setFixedHeader() {
    if (window.scrollY >= 60 || window.pageYOffset >= 60) {
      document.getElementsByClassName("js-header")[0].classList.add("fixed");
    } else {
      document.getElementsByClassName("js-header")[0].classList.remove("fixed");
    }
  };

  /// header burger

  var burger = document.querySelector(".js-burger");
  var header = document.querySelector(".js-header");
  var body = document.querySelector("body");
  function toggleMenu() {
    var burgerClasses = burger.classList.value;
    if (burgerClasses.includes("active")) {
      burger.classList.remove("active");
      header.classList.remove("show-menu");
      body.style.overflow = "visible";
    } else {
      burger.classList.add("active");
      header.classList.add("show-menu");
      body.style.overflow = "hidden";
    }
  }

  // header menu mobile

  // copyright

  var copyright = document.getElementById("js-copyright");
  if (copyright) {
    var date = new Date().getFullYear();
    copyright.innerHTML = date;
  }

  // icon size toggle

  $(document).on("click", '.js-icon-view-btn', function (e) {
    e.preventDefault();
    var activeView = $(this).data("view");
    $(".js-icon-view-btn").removeClass('active');
    $(this).addClass('active');
    $('.js-partners-hub-list').removeClass('column row');
    $(".js-partners-hub-list").addClass(activeView);
  });

  // slider slick

  $(".js-quotes-slider").slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    infinite: false,
    responsive: [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: 991,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 580,
      settings: {
        slidesToShow: 1
      }
    }]
  });
  $(".js-brand-list-slider").slick({
    infinite: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    responsive: [{
      breakpoint: 1440,
      settings: {
        slidesToShow: 6
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 4
      }
    }, {
      breakpoint: 580,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: 480,
      settings: {
        slidesToShow: 2
      }
    }]
  });
  $(".js-results-slider").slick({
    infinite: true,
    variableWidth: true,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false
  });
  $(".js-solution-slider").slick({
    infinite: true,
    variableWidth: true,
    slidesToScroll: 1,
    autoplay: true,
    prevArrow: $(".js-button-left"),
    nextArrow: $(".js-button-right")
  });

  // page slider
  var currentSlide = 0;
  var slides = $(".js-page-slider");
  function updateSlides() {
    slides.each(function (index) {
      if (index === currentSlide) {
        $(this).css("transform", "translateY(0)");
      } else if (index < currentSlide) {
        $(this).css("transform", "translateY(0)");
      } else {
        $(this).css("transform", "translateY(150%)");
      }
      if (currentSlide === slides.length - 1) {
        $(".js-arrow-right").fadeOut();
      } else {
        $(".js-arrow-right").fadeIn();
      }
    });
  }
  function nextSlide() {
    if (currentSlide < slides.length - 1) {
      currentSlide++;
      updateSlides();
    }
  }
  function prevSlide() {
    if (currentSlide > 0) {
      currentSlide--;
      updateSlides();
    }
  }
  $(".js-arrow-right").click(nextSlide);
  // $(".js-arrow-left").click(prevSlide);

  var startY = null;

  // on desktop
  $(".js-page-slider-wrapper").on("mousedown", function (e) {
    startY = e.clientY;
  });
  $(document).on("mousemove", function (e) {
    if (startY === null) return;
    var deltaY = e.clientY - startY;
    if (deltaY > 50) {
      prevSlide();
      startY = null;
    } else if (deltaY < -50) {
      nextSlide();
      startY = null;
    }
  });
  $(document).on("mouseup", function () {
    startY = null;
  });

  // on mobile devices
  $(".js-page-slider-wrapper").on("touchstart", function (e) {
    startY = e.originalEvent.touches[0].clientY;
    // e.preventDefault();
  });
  $(".js-page-slider-wrapper").on("touchmove", function (e) {
    var clientY = e.originalEvent.touches[0].clientY;
    var deltaY = clientY - startY;
    if (deltaY > 50) {
      e.preventDefault();
      if (deltaY > 0) {
        prevSlide();
      } else {
        nextSlide();
      }
      startY = null;
    }
  });
  $(document).on("touchend", function () {
    startY = null;
  });
  updateSlides();

  // timer redirect

  function startTimer() {
    var timerElement = document.querySelector(".js-timer");
    if (!timerElement) return;
    var timeLeft = 10;
    var redirectUrl = timerElement.getAttribute("data-redirect-to");
    var countdown = setInterval(function () {
      timerElement.textContent = timeLeft;
      if (timeLeft === 0) {
        clearInterval(countdown);
        window.location.href = redirectUrl;
      }
      timeLeft--;
    }, 1000);
  }
  startTimer();
  //filter

  $(".js-filter-toggle").click(function () {
    var $element = $(this);
    $element.closest(".js-filter-toggle-wrapper").find(".js-filter-toggle.active").removeClass("active");
    $element.addClass("active");
    if ($element.data("filter-value") === "all") {
      $(".js-filtered-items-container").addClass("shown-all");
    } else {
      $(".js-filtered-items-container").removeClass("shown-all");
    }
    var filters = {};
    $(".js-filter-toggle.active").each(function () {
      var $activeElement = $(this);
      var filterName = $activeElement.data("filter-name");
      var filterValue = $activeElement.data("filter-value");
      if (filterValue !== "all") {
        $(".js-filtered-items-container").removeClass("shown-all");
        filters[filterName] = filterValue;
      }
    });
    $(".js-filtered-item").each(function () {
      $element = $(this);
      var show = true;
      Object.entries(filters).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];
        if ($element.data(key) !== value) {
          show = false;
        }
      });
      if (show) {
        $element.show();
      } else {
        $element.hide();
      }
    });
  });

  // $(".menu-item-has-children").on("click", function (event) {
  //   if (window.innerWidth < 991 && !$(this).hasClass("active")) {
  //     event.preventDefault();
  //   }
  //   $(this).toggleClass("active");
  // });

  $(".js-submenu-content").on("click", function (e) {
    e.preventDefault();
    $(this).closest('.contact-menu').toggleClass("active");
  });
  $('.js-submenu-close').click(function () {
    $(this).closest('.contact-menu').removeClass("active");
  });

  // modal

  $(".js-open-modal").on("click", function (e) {
    e.preventDefault();
    var modalId = $(this).data("modal");
    $(modalId).fadeIn();
    $("body").addClass("open-modal");
  });
  function openModal() {
    var content = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var innerClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var $modal = $("<div class=\"modal-container js-modal-container js-created-modal\">\n      <div class=\"modal js-modal\">\n        <div class=\"modal-item js-modal-inner\" style=\"display: none;\">\n          <div class=\"modal-inner ".concat(innerClass, "\">\n            <div class=\"modal-close js-modal-close\">\n              <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"32\" height=\"32\" fill=\"none\"><script xmlns=\"\"/><path d=\"M24 8L8 24M8 8l16 16\" opacity=\".4\" stroke=\"#000\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><script xmlns=\"\"/></svg>\n            </div>\n            <div class=\"modal-body\">\n              ").concat(content, "\n            </div>\n          </div>\n        </div>\n      </div>\n    </div>"));
    $("body").append($modal);
    $modal.find('.js-modal-inner').fadeIn();
    $("body").addClass("open-modal");
    $modal.find('.js-modal-close').click(function () {
      $("body").removeClass("open-modal");
      $(".js-modal-inner").fadeOut();
      setTimeout(function () {
        $modal.remove();
      }, 400);
    });
  }
  window.openModal = openModal;
  function closeModal() {
    $("body").removeClass("open-modal");
    $(".js-modal-inner").fadeOut();
  }
  $(document).mouseup(function (e) {
    var div = $(".js-modal-inner");
    if (!div.is(e.target) && div.has(e.target).length === 0) {
      var removeModal = $(e.target).closest('.js-created-modal');
      closeModal();
      if (removeModal) {
        setTimeout(function () {
          removeModal.remove();
        }, 400);
      }
    }
  });
  $(".js-modal-close").on("click", closeModal);

  // nice-select
  $("select").niceSelect();

  // tabs

  var tabHead = $(".js-tab-head .tab-item");
  var tabBodyContainer = $(".js-tabs-content");
  tabHead.on("click", function () {
    var tab = $(this);
    if (tab.hasClass('active')) {
      return;
    }
    var headContainer = tab.parents(".js-tab-head");
    var tabId = tab.data("tab");
    headContainer.find("li").removeClass("active");
    tabBodyContainer.find(".tab-body").hide();
    tab.addClass("active");
    $(tabId).show();
  });

  // smooth hover content

  $(".js-smooth-content li").click(function () {
    var _this = this;
    $(".js-smooth-content li.active").removeClass("active");
    $(".js-smooth-content li .module-text p").css("opacity", 0);
    $(this).addClass("active");
    setTimeout(function () {
      $(_this).find('.module-text p').css("opacity", 1);
    }, 300);
  });
  ;
  burger.addEventListener("click", toggleMenu);
  window.addEventListener("scroll", function () {
    setFixedHeader();
  });
  setFixedHeader();
  AOS.init();
}
$(App);